import "lazysizes";
import "picturefill";

//ヘッダーメニュー
$("#header__spBtn, .header__spMenu__item").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($("#header__spBtn").hasClass("is-active")) {
    $("#header__spBtn").removeClass("is-active");
    $("#header__spMenu").removeClass("is-active");
  } else {
    $("#header__spBtn").addClass("is-active");
    $("#header__spMenu").addClass("is-active");
  }
});

$(".header__pcMenu__modal").hover(
  function () {
    $(".header__pcMenu__accordion").addClass("active");
  },
  function () {
    $(".header__pcMenu__accordion").removeClass("active");
  }
);

//アコーディオン
$(".faq__item__q").each(function () {
  $(this).on("click", function () {
    $("+div", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

$(".work__list").slick({
  slidesToShow: 3,
  centerMode: true,
  centerPadding: "15%",
  arrows: false,
  dots: false,
  // autoplay: true,
  // autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 835,
      settings: {
        slidesToShow: 1,
        centerPadding: "12%",
      },
    },
  ],
});

// スムーススクロール
$('a[href^="#"]').click(function () {
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? "html" : href);
  const position = target.offset().top;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});
